'use strict'

module.exports.default= {
	NODE_ENV: 'development',
        MS_SOCO_PUBLIC_API_URL: 'https://api.soco.id',
        MS_SOCO_ADMIN_API_URL: 'https://admin-api.soco.id',
        MS_SOCIOLLA_API_URL: 'https://www.sociolla.com',
        MS_LULLA_API_URL: 'https://lulla-api.sociolla.com',
	MS_BJ_PUBLIC_API_URL: 'https://bj-public-api.soco.id',
        MS_BJ_ADMIN_API_URL: 'https://bj-admin-api.sociolla.com',
        SOCO_WEB_URL: 'https://www.soco.id',
        MS_SOCKET_API_URL : 'https://websocket.soco.id',
        SOCIOLLA_WEB_URL: 'https://www.sociolla.com',
        SOCIOLLA_VN_ADMIN_URL: 'https://vn-admin.sociolla.info',
	MS_ANALYTICS_API_URL : 'https://analytics-api.soco.id',
	MS_SOCIOLLA_PUBLIC_API_URL: 'https://sociolla-api.carasunbeauty.com',
	PREZENT_WEB_URL : "https://prezent.id/redeem/",
	SOCIOLLA_WEB_VN_URL : 'https://vn.sociolla.com',
	FB_APP_ID: "357222842020135",
	REVIEW_WEB_URL: 'https://review.soco.id',
        MS_CHATBOT_API_URL : 'https://chatbot-api.sociolla.info',
	MS_ACCOUNTS_API_URL : 'https://accounts-api.soco.id',
	BJ_WEB_URL: 'https://journal.sociolla.com',
	MS_B2B_ACCOUNTS_API_URL   : 'https://b2b-accounts.sociolla.info',
	MS_NEXUS: 'https://nexus-api.sociolla.com',
 MS_B2B_API_URL     : 'https://b2b-api.sociolla.info',
 MS_B2B_ORDERS_API_URL  : 'https://b2b-orders.sociolla.info',
 MS_B2B_CATALOG_API_URL   : 'https://b2b-catalog.sociolla.info',
 B2B_WEB_URL      : 'https://nina.sociolla.info',
	STORE_DEEPLINK: 'https://sociolla.app.link/zViOcjLVBpb',
        FIREBASE_CONFIG: {
    appName: 'SHIELD',
    apiKey: 'AIzaSyBo52TU4KVta_S22Ut1vU2YaP6voVRCQ3U',
    authDomain: 'sociolla-messaging.firebaseapp.com',
    databaseURL: 'https://sociolla-messaging.firebaseio.com',
    projectId: 'sociolla-messaging',
    storageBucket: 'sociolla-messaging.appspot.com',
    messagingSenderId: '546977597662',
    appId: '1:546977597662:web:b1f40801821f842e665a4f',
  },
  FIREBASE_VAPID_KEY: 'BIpTilicc2P-003pw16WXCMznLYr8PddPo0NRt5yCuNeLwU_kRTXFqRWcqcRteV4W7474oPlhwTKqT3-ug1A61M',
JARVIS_WEB_VN_URL : "https://vn-jarvis.sociolla.info/",
	MS_REVIEW_API_URL: 'https://review-api.soco.id',
	CARASUN_WEB_URL: "https://www.carasunbeauty.com",
        MS_INTERNAL_COURSE_API_URL: "https://internal-course-api.sociolla.info",
	JARVIS_WEB_URL : "https://jarvis.sociolla.info/",
	BranchKey: 'key_live_mf1l06X7fHurf6JGSZ1piaeouzfcNM0O',
	STORE_WEB_URL: 'https://store-kota.sociolla.com',
        BJ_ADMIN_URL: 'https://admin-bj.sociolla.com',
        SOCO_ADMIN_URL: 'https://admin.soco.id',
	MAP: 'google',
        GOOGLE_MAP_KEY: "AIzaSyAmrtZe_iHdzj0i7i1jjqnjrPtomG0B0is",
	MS_COSRX_ACADEMY_API_URL: 'https://cosrxacademy-api.cosrx.id',
        LILLA_WEB_URL: 'https://www.lilla.id',
	LULLA_WEB_URL: 'https://lilla.id',
	MS_ORDER_API_URL: 'https://orders-api.soco.id',
        MS_CHATBOT_SOCKET_API_URL: 'https://chatbot-socket-api.sociolla.info',
	HRMS_WEB_URL : 'https://shield.sociolla.info',
	MS_HRMS_API_URL :  'https://shield-api.sociolla.info',
	MS_CATALOG_API_URL : 'https://catalog-api.speakstomefragrance.com',
        MS_CART_API_URL: 'https://carts-api.soco.id',
        MS_SHIPPING_API_URL: 'https://shipping-api.soco.id',
        MS_PAYMENT_API_URL: 'https://payments-api.soco.id',
	RECAPTCHA_SITE_KEY: '6LdGhu0UAAAAAHRDh2bM97TEDB9UMBZSLjtsKe4F',
	MS_JARVIS_API_URL : 'https://jarvis-api.sociolla.info',
	MS_SANCTUM_API_URL : "https://sanctum-api.sociolla.net/",
        MS_PLANOGRAM_API_URL : 'https://planogram-api.sociolla.info',
	SANCTUM_WEB_URL : "https://sanctum.sociolla.net/",
	BJ_APPS_URL : 'https://www.beautyjournal.id',
	gpn: {
		url: 'https://bpg.bersama.id/ArtajasaPG/payment/salesRedirect'
	},
	MS_SSO_BROKER_API_URL: 'https://sso-broker.speakstomefragrance.com',
        store_lists: [
  {id: 5, value: 'Sociolla Kota Kasablanka'},
  {id: 6, value: 'Sociolla Lippo Mall Puri'},
  {id: 15, value: 'Sociolla Tunjungan Plaza 6'},
  {id: 16, value: 'Sociolla Bintaro Exchange Jakarta'},
  {id: 19, value: 'Sociolla 23 Paskal'},
  {id: 18, value: 'Sociolla Lippo Mall Kemang Village'},
  {id: 20, value: 'Sociolla Kelapa Gading'},
  {id: 21, value: 'Sociolla Pakuwon Mall'},
  {id: 17, value: 'Sociolla Senayan Park'},
  {id: 23, value: 'Sociolla Beach Walk'},
	 {id: 24, value: 'Sociolla Cibinong City Mall'},
  {id: 25, value: 'Sociolla Aeon Sentul'},
  {id: 22, value: 'Sociolla Gandaria City'},
  {id: 26, value: 'Sociolla Grand Indonesia'},
  {id: 27, value: 'Sociolla Central Park'},
  {id: 28, value: 'Sociolla Sun Plaza Medan'}, 
],
        MS_POS_API_URL: 'https://pos-api.soco.id',
        store_id: 5,
	MS_CATALOG_API_LIST : ['https://catalog-api.speakstomefragrance.com'],
 MS_CART_API_LIST : ['https://carts-api.soco.id'],
        MS_SOCO_PUBLIC_API_LIST: ['https://api.soco.id','https://api1.soco.id','https://api2.soco.id','https://api3.soco.id','https://api4.soco.id','https://api5.soco.id','https://api6.soco.id','https://api7.soco.id','https://api8.soco.id','https://api9.soco.id'],
	MS_BJ_PUBLIC_API_LIST: ['https://bj-public-api.soco.id','https://bj-public-api1.soco.id','https://bj-public-api2.soco.id','https://bj-public-api3.soco.id','https://bj-public-api4.soco.id']
}

